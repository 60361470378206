import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withQuoteWizard } from '../components/withQuoteWizard';
// import CheckmarkIcon from '../images/tickcheckmark.svg';
import { find } from 'lodash';
import SEO from '../components/SEO';

import Hero from '../components/Hero/Hero';
import TextBlock from '../components/TextBlock';
import CheckmarkIcon from '../components/CheckmarkIcon';

const styles = theme =>
  createStyles({
    root: {},
    imageWrap: {
      height: '30vh',
      overflow: 'hidden',
    },
    articleWrap: {
      padding: '40px',
      backgroundColor: theme.palette.common.white,
    },
    contentWrap: {
      maxWidth: 960,
      backgroundColor: theme.palette.common.white,
    },
    quote: {
      padding: 45,
      backgroundColor: '#E9E9E9'
    },
    quoteWrap: {
      padding: '40px 40px 0',
      [theme.breakpoints.up('md')]: {
        padding: '40px',
      }
    },
    paragraph: {
      paddingTop: 10,
      paddingBottom: 15,
    }
  });


const ThePage = (props) => {
  const { classes } = props;
  const article = props.pageContext.article;
  const { headline, texts, heroImg } = article;

  const intro = find(texts, ['type', 'intro']);
  const outro = find(texts, ['type', 'outro']);
  const quote = find(texts, ['type', 'quote']);

  const quoteMDWidth = 5;
  const contentMDWidth = quote ? 7 : 12;

  return (
    <React.Fragment>
      <SEO
        title="Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
          `Turf`,
          `Lawns`,
          `Best Lawns`,
          `Australia’s Best Lawns`,
          `lawn producer`,
        ]}
      />

      <Hero heroImage={heroImg} noQuote>
        <TextBlock text={headline} />
      </Hero>

      <Grid container justify="center" style={{ backgroundColor: "#fff" }}>
        <Grid container item className={classes.contentWrap}>
          {quote && <Grid
              item
              md={quoteMDWidth}
              className={classes.quoteWrap}
            >
              <Typography
                className={classes.quote}
                variant="h6"
                component="aside"
              >
                {quote.copy[0]}
              </Typography>
            </Grid>
          }
          <Grid
            item
            container
            direction="column"
            className={classes.articleWrap}
            md={contentMDWidth}
          >
            <React.Fragment>
              {intro && <Typography variant="body1" component="p">
                {intro.copy[0]}
                </Typography>
              }

              
              {texts.map(({ type, copy }) => {
                let content;

                switch (type) {
                  case 'paragraph':
                    content = (
                      <Typography key={copy} variant="body1" component="p"
                        className={classes.paragraph}
                      >
                        {copy[0]}
                      </Typography>
                    );
                      break;
                  case 'checklist':
                    content = (
                      <List
                        key={copy}
                        component="ul"
                      >
                        {copy.map(text => (
                          <ListItem component="li" key={text}>
                            <ListItemIcon>
                              <CheckmarkIcon />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                          </ListItem>
                        ))}
                      </List>
                    );
                      break;
                  case 'tip':
                    content = (
                      <Typography key={copy} variant="body1" component="p">
                        <strong>TURF TIP:</strong> {copy[0]}
                      </Typography>
                    );
                      break;
                  default:
                    content = null;
                }

                  return content;
              })}

              {outro && <Typography variant="body1" component="p">
                {outro.copy[0]}
                </Typography>
              }
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ThePageWrapped = withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
)


export default ThePageWrapped;
