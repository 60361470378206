import React from 'react';
import CheckmarkIcon from '../images/tickcheckmark.svg';

export default ({ iconProps: { style, ...iconPropsRest } = {}, ...rest }) => {
  const iconStyle = {
    ...{
      display: 'inline-box',
      height: 24,
      width: 24,
      padding: 0,
      margin: 0,
    },
    ...(style || {})
  };

  return (
    <img src={CheckmarkIcon} alt="" style={iconStyle} {...iconPropsRest} />
  );
};
